export const logos = [
  {
    display: '/allochiria_logo.jpg',
    src: '/logos/allochiria_logo.pdf',
    alt: 'ALLOCHIRIA logo (pdf)',
  },
  {
    display: '/logos/allochiria_logo_invert.jpg',
    src: '/logos/allochiria_logo_invert.pdf',
    alt: 'ALLOCHIRIA logo inverted (pdf)',
  },
  {
    display: '/allochiria_logo.jpg',
    src: '/logos/allochiria_Vector_Logo_wh.ai',
    alt: 'ALLOCHIRIA Vector Photoshop Image (ai)',
  },
  //   {
  //     src: '/allochiria_logo.jpg',
  //     alt: 'ALLOCHIRIA (jpg)',
  //   },
  //   {
  //     src: '/logos/allochiria_logo_invert.jpg',
  //     alt: 'ALLOCHIRIA inverted (jpg)',
  //   },
  //   {
  //     src: '/allochiria_logo_trans_black.png',
  //     alt: 'ALLOCHIRIA - Transparent black (png)',
  //   },
  //   {
  //     display: '/logos/allochiria_logo_invert.jpg',
  //     src: '/logos/allochiria_logo_trans_white.png',
  //     alt: 'ALLOCHIRIA - Transparent white (png)',
  //   },
  {
    src: '/a_logo.png',
    alt: 'A (png)',
  },
  {
    src: '/logos/a_logo_invert.png',
    alt: 'A inverted (png)',
  },
  //   {
  //     src: '/a_logo_transparent_black.png',
  //     alt: 'A - Transparent black (png)',
  //   },
  {
    display: '/logos/allochiria_logo_invert.jpg',
    src: '/allochiria_logo_projection.jpg',
    alt: 'ALLOCHIRIA logo for screen projection (jpg)',
  },
];
