import { Routes, Route } from 'react-router-dom';
import { SplashScreen } from './SplashScreen';
import { BandPhoto } from './BandPhoto';
import { Section } from './components/Section';
import { Masthead } from './Masthead';
import { BandBio } from './BandBio';
import { Shows } from './Shows';
import { Discography } from './Discography';
import { Contact } from './Contact';
import { Footer } from './Footer';
import { EPK } from './EPK';
import { EPKNew } from './EPKNew';
import { ShowsArchive } from './ShowsArchive';
import { Media } from './Media';
import styled from 'styled-components';
import { EPKTourPack } from './EPKTourPack';

const SPA = () => (
  <div>
    <SplashScreen />
    <Masthead />
    <Section id="band">
      <BandPhoto />
      <BandBio />
    </Section>
    <Section id="shows">
      <Shows />
    </Section>
    <Section id="discography">
      <Discography />
    </Section>
    <Section id="media">
      <Media />
    </Section>
    <Section id="contact">
      <Contact />
    </Section>
  </div>
);

const SectionWithBg = styled.div`
  background-image: url('right_bg.png');
  background-color: white;
  background-size: auto 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: right center;
`;

const MobileOpacity = styled.div`
  background-color: rgba(255, 255, 255, 0.3);

  @media only screen and (max-width: 768px) {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const App = () => {
  return (
    <SectionWithBg>
      <MobileOpacity>
        <Routes>
          <Route path="/" element={<SPA />} />
          <Route path="/epk-short" element={<EPK />} />
          <Route path="/epk" element={<EPKNew />} />

          <Route
            path="/epk/tour"
            element={
              <>
                <Masthead isEPK />
                <EPKTourPack />
              </>
            }
          />
          <Route path="/shows-archive" element={<ShowsArchive />} />
          <Route
            path="/shows"
            element={
              <>
                <Masthead />
                <Section>
                  <Shows />
                </Section>
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Masthead />
                <Section>
                  <Contact />
                </Section>
              </>
            }
          />
          <Route path="*" element={<SPA />} />
        </Routes>
        <Section>
          <Footer />
        </Section>
      </MobileOpacity>
    </SectionWithBg>
  );
};

export default App;
