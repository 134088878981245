export const epkLinks = [
  {
    id: 'bio',
    link: '/files/allochiria_bio.pdf',
    // link: 'https://www.dropbox.com/scl/fi/y5l6sfjo2ausi0hc1icjm/Allochiria-bio-EN-2025.pdf?rlkey=gduxu8gjzypioikz8d3g2zfd2&dl=0',
    label: 'Biography (pdf)',
  },
  {
    id: 'tour-rider',
    link: 'https://www.dropbox.com/scl/fi/op8tl7demaattant8fg7t/ALLOCHIRIA-2025-TOUR-RIDER-full.pdf?rlkey=6lgls6uehxr86ytf7upsbo8eu&dl=0',
    label: 'Tour rider 2025 (pdf)',
  },
  {
    id: 'tech-rider-tour',
    link: 'https://www.dropbox.com/scl/fi/go7pmxl2l4l5wcfdroezo/ALLOCHIRIA-2025-TECH-RIDER.pdf?rlkey=1jitkskikq3275739nndrqlm3&dl=0',
    label: 'Technical rider (pdf)',
  },
  // {
  //   link: 'https://www.dropbox.com/scl/fi/eyebqwny4nbtj2c5uzwgm/ALLOCHIRIA-2024-TECH-RIDER.pdf?rlkey=it7k5jz7oba9yt13m5akajwq7&dl=0',
  //   label: 'Technical rider (pdf)',
  // },
  {
    id: 'may-2025-tour',
    link: 'https://www.dropbox.com/scl/fo/mth8igegyvt1grfuhal60/AJwX1Jq1QG-_W3KcGTsd0pQ?rlkey=q5on47d1x7qy9s1ydz0bdz76g&dl=0',
    label: 'May tour 2025 posters & templates (Dropbox)',
  },
  {
    id: 'photos',
    link: 'https://www.dropbox.com/scl/fi/wruyrtp8rs0g6ahgng2w3/allochiria-photos-2023.zip?rlkey=fkhr5cytmnyxqbbm3fktf3ov8&dl=0',
    label: 'Band photos (Dropbox)',
  },
  {
    id: 'logos',
    link: 'https://www.dropbox.com/sh/uv4ult9x0hstoyv/AADzHBc__kobC8e7WG2nZqrva?dl=0',
    label: 'Logo pack (Dropbox)',
  },
];
